const handleRequestMixin = {
  methods: {
    async handleRequestMixin_alertError(callback, customMessage) {
      try {
        if (typeof callback === "function") {
          const response = await callback();
          return response;
        }
      } catch (err) {
        this.$store.dispatch("callAlert", customMessage || err.message);
      }
    },
  },
};

export default handleRequestMixin;
