import sendRequest from "../../app/utils/send-request-helper";
import uploadImage from "../../app/utils/upload-image-helper";

export const checkRemoveAndAddItem = (updatedList, originalList) => {
  const valueOriginalList = originalList.map((item) => item.value);
  const newItems = updatedList.filter((item) => {
    return !valueOriginalList.includes(item);
  });
  const removeItems = originalList.filter((item) => {
    return !updatedList.includes(item.value);
  });
  return {
    newItems,
    removeItems,
  };
};

const updateItemList = async ({
  updateItems,
  originItems,
  addRequest,
  removeRequest,
}) => {
  const { newItems, removeItems } = checkRemoveAndAddItem(
    updateItems,
    originItems
  );
  // update new items
  if (newItems.length > 0) {
    await addRequest(newItems);
  }
  // remove items
  if (removeItems.length > 0) {
    for (let item of removeItems) {
      await removeRequest(item);
    }
  }
};

export const updateUserSkills = async ({
  updateSkills,
  originSkills,
  userId,
}) => {
  const addRequest = async (newItems) => {
    return await sendRequest({
      url: `${process.env.VUE_APP_URL}/relationships/has-skill`,
      method: "POST",
      body: {
        id: userId,
        skills: newItems,
      },
      isAuth: true,
    });
  };
  const removeRequest = async (item) => {
    await sendRequest({
      url: `${process.env.VUE_APP_URL}/relationships/has-skill?skill_id=${item.id}&id=${userId}`,
      method: "DELETE",
      isAuth: true,
    });
  };
  await updateItemList({
    updateItems: updateSkills,
    originItems: originSkills,
    addRequest,
    removeRequest,
  });
};

export const updateUserInterests = async ({
  updateInterests,
  originInterests,
  userId,
}) => {
  const addRequest = async (newItem) => {
    return await sendRequest({
      url: `${process.env.VUE_APP_URL}/relationships/has-interest`,
      method: "POST",
      body: {
        id: userId,
        interests: newItem,
      },
      isAuth: true,
    });
  };
  const removeRequest = async (item) => {
    await sendRequest({
      url: `${process.env.VUE_APP_URL}/relationships/has-interest?interest_id=${item.id}&id=${userId}`,
      method: "DELETE",
      isAuth: true,
    });
  };
  await updateItemList({
    updateItems: updateInterests,
    originItems: originInterests,
    addRequest,
    removeRequest,
  });
};

export const updateUserValues = async ({
  updateValues,
  originValues,
  userId,
}) => {
  const addRequest = async (newItem) => {
    return await sendRequest({
      url: `${process.env.VUE_APP_URL}/relationships/has-value`,
      method: "POST",
      body: {
        id: userId,
        values: newItem,
      },
      isAuth: true,
    });
  };
  const removeRequest = async (item) => {
    await sendRequest({
      url: `${process.env.VUE_APP_URL}/relationships/has-value?value_id=${item.id}&id=${userId}`,
      method: "DELETE",
      isAuth: true,
    });
  };
  await updateItemList({
    updateItems: updateValues,
    originItems: originValues,
    addRequest,
    removeRequest,
  });
};

export const updateUserProfile = async (updatedUser, userId, originAvatar) => {
  let imageUrl = "";
  // type does not allow to update
  // eslint-disable-next-line no-unused-vars
  const { avatar, type, ...requestBody } = updatedUser;
  // check and upload image
  if (avatar && avatar !== "") {
    imageUrl = await uploadImage(avatar, {
      fileUrl: originAvatar,
      resourceId: userId,
      resourceType: "user",
    });
    requestBody.avatar = imageUrl;
  } else if (avatar === "") {
    requestBody.avatar = "";
  }
  const user = await sendRequest({
    url: `${process.env.VUE_APP_URL}/resources/users/${userId}`,
    method: "PUT",
    isAuth: true,
    body: requestBody,
  });
  return user;
};
